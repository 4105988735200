<template>
  <el-dialog v-model="show" title="人员选择" width="40%" :before-close="close">
      <div>
        <div class="person-title">已选择人员</div>
        <div class="select-person">
          <el-tag v-for="(item, index) in persons" :key="index" class="mx-1" closable @close="handleDelPerson(item)">
            {{ item.nickname }}
          </el-tag>
        </div>
        <div class="search">
          <el-input v-model="personName" placeholder="请输入人员姓名搜索"/>
        </div>
        <el-table :data="searchData" 
          v-loading="loading"
          style="width: 100%" 
          @selection-change="handleSelectionChange" height="400">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="index" label="序号" width="100" />
          <el-table-column prop="nickname" label="姓名" />
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
</template>

<script setup>
  import { ref, defineProps, defineEmits, watch } from 'vue';
  import { ElMessage } from 'element-plus';
  // api
  import { GET_USER_LIST } from '@/api';


  const emits = defineEmits(['onClose', 'onConfirm']);
  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    },
    selectPersons: { // 选择的人一样
      type: Array,
      default: () => []
    }
  })
  const show = ref(false);
  const loading = ref(false);

  const tableData = ref([]);
  const searchData = ref([]);

  const multipleSelection = ref([]); // 多选
  const personName = ref(''); // 搜索名字
  const persons = ref([]); // 选中人员

  const init = async () => {
    loading.value = true;
    const res = await GET_USER_LIST();
    loading.value = false;
    if (res?.httpCode === 200) {
      tableData.value = res.msg ? res.msg.map((item, index) => ({index: index + 1, nickname: item.nickname, openid: item.openid})): [];
      search();
    }
  }

  // 多选
  const handleSelectionChange = (vals) => {
    const adds = [];
    vals.map(item => {
      const bool = persons.value.some(v => v.openid === item.openid);
      if (bool === false) {
        adds.push(item);
      }
    });
    persons.value = [...persons.value, ...adds];
  }

   // 搜索
   const search = (val) => {
    if (val) {
      searchData.value = tableData.value.filter(item => item.nickname.indexOf(val) >= 0);
    } else {
      searchData.value =  JSON.parse(JSON.stringify(tableData.value));
    }
  }

  // 删除选中人员
  const handleDelPerson = (item) => {
    persons.value = persons.value.filter(v => v.openid !== item.openid);
  }

  const close = () => {
    emits('onClose');
  }

  const submit = () => {
    if (persons.value.length === 0) {
      ElMessage.warning('请选择人员');
      return;
    }
    const ids = persons.value.map(item => item.openid);
    emits('onConfirm', ids.join(','));
  }


  watch(() => props.visible, (val) => {
    show.value = val;
    multipleSelection.value = [];
    if (val) {
      persons.value = props.selectPersons;
      init();
    }
  });

  watch(() => personName.value, (val) => {
    search(val);
  })
</script>

<style lang="scss" scoped>
.select-person{
  margin: 10px 0;
  min-height: 20px;
  max-height: 50px;
  overflow-y: auto;
  & > span {
    margin-right: 5px;
    margin-bottom: 3px;
  }
}
.search{
  margin-bottom: 10px;
  .el-input{
    width: 300px;
  }
}
</style>