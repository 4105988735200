
<template>
  <el-upload
    class="avatar-uploader"
    :action="BasePath"
    accept="image/*"
    :show-file-list="false"
    :headers="headers"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<script setup>
import { ref, reactive, defineEmits, defineProps, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

//
import { token, url } from '@/utils/config.js';

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  }
});
const emits = defineEmits(['update:modelValue']);
const BasePath = ref(url+ '/upload');
const imageUrl = ref('')
const headers = reactive({
  Token: token
});

const handleAvatarSuccess = response => {
  if (response.httpCode === 200) {
    const { msg } = response;
    if (msg?.path) {
      emits('update:modelValue', msg.path);
    }
  }
}

const beforeAvatarUpload = (rawFile) => {
 if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}

watch(() => props.modelValue, (val) => {
  if (val) {
    imageUrl.value = `${url}/${val}`;
  } else {
    imageUrl.value = '';
  }
}, 
{
  immediate: true
})

</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>