<template>
  <div>
    <div class="btns">
      <el-button @click="add">新增</el-button>
      <el-popconfirm 
      confirm-button-text="确认"
      cancel-button-text="取消"
      @confirm="resetVote"
      title="重置投票将清空投票信息，确认执行?">
        <template #reference>
          <el-button>重置投票</el-button>
        </template>
      </el-popconfirm>
    </div>
    <el-table :data="tableData" style="width: 100%" height="600" v-loading="loading">
      <el-table-column prop="index" label="序号" width="100" />
      <el-table-column prop="name" label="项目名称" width="300"/>
      <el-table-column prop="person" label="参与人员"/>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="edit(scope.row)">修改</el-button>
          <el-button link type="primary" size="small" @click="addPerson(scope.row)">添加人员</el-button>
          
          <el-popconfirm 
          confirm-button-text="确认"
          cancel-button-text="取消"
          @confirm="del(scope.row)"
          title="删除节目无法恢复，确认执行?">
            <template #reference>
              <el-button link type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 活动添加修改 -->
    <el-dialog v-model="visible" title="添加活动" width="40%" :before-close="close">
      <el-form label-width="100px" style="max-width: 460px" >
        <el-form-item label="名称" required>
          <el-input v-model="formLabelAlign.name" maxlength="30"/>
        </el-form-item>
        <el-form-item label="图片" required>
          <FileUpload v-model="formLabelAlign.picture"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 人员 -->
    <SelectPerson :visible="personVisible" :selectPersons="selectPersons" @onClose="personVisible = false" @onConfirm="changePerson"></SelectPerson>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive } from 'vue';
  import { ElMessage } from 'element-plus';
  // api
  import { GET_PROGRAM_LIST, POST_ADD_PROGRAM, POST_EDIT_PROGRAM, POST_DEL_PROGRAM, POST_PROGRAM_USERS, POST_RESET_VOTE } from '@/api';
  // 组件
  import FileUpload from '@/page/components/fileUpdload.vue';
  import SelectPerson from './selectPerson.vue';

  const tableData = ref([]);
  const loading = ref(false);
  const formLabelAlign = reactive({
    sid: '',
    name: '',
    picture: ''
  })
  const visible = ref(false);
  const personVisible = ref(false);
  const selectPersons = ref([]); // 选中的人员

  onMounted(() => {
    init();
  });

  // 获取节目列表
  const init = async () => {
    loading.value = true;
    const res = await GET_PROGRAM_LIST();
    loading.value = false;
    if (res.msg) {
      tableData.value = res.msg.map((item, index) => {
        let owner = item.owner ? item.owner : [];
        owner = owner.map(v => v.nickname);
        return {
          index: index + 1,
          ...item,
          ...item.programme,
          person: owner.join(',')
        }
      })
    } else {
      tableData.value = [];
    }
  }

  const add = () => {
    formLabelAlign.sid = '';
    formLabelAlign.name = '';
    formLabelAlign.picture = '';
    visible.value = true;
  }

  const close = () => {
    visible.value = false;
  }

  const edit = row => {
    visible.value = true;
    formLabelAlign.sid = row.programme.sid;
    formLabelAlign.name = row.programme.name;
    formLabelAlign.picture = row.programme.picture;
  }

  const del = async row => {
    const res = await POST_DEL_PROGRAM({sid: row.sid});
    if (res) {
      ElMessage.success('删除成功');
      init();
    }
  }

  // 添加人员
  const addPerson = async (row) => {
    formLabelAlign.sid = row.sid;
    if (row.owner && row.owner.length > 0) {
      selectPersons.value = row.owner.map(item => item);
    } else {
      selectPersons.value = [];
    }
    personVisible.value = true;
  }

  const submit = async () => {
    let res;
    if (!formLabelAlign.name) {
      ElMessage.warning('请输入节目名称');
      return;
    }
    if (!formLabelAlign.picture) {
      ElMessage.warning('请上传节目图片');
      return;
    }

    if (formLabelAlign.sid) {
      res = await POST_EDIT_PROGRAM(formLabelAlign);
    } else {
      res =  await POST_ADD_PROGRAM(formLabelAlign);
    }
    if(res?.httpCode === 200) {
      ElMessage.success(formLabelAlign.sid ? '修改成功': '添加成功');
      init();
      visible.value = false;
    }
  }

  // 人员修改
  const changePerson = async (val) => {
    const params = {
      openidList: val,
      programSid: formLabelAlign.sid
    };
    const res = await POST_PROGRAM_USERS(params);
    if (res?.httpCode === 200) {
      ElMessage.success('添加人员成功！');
      personVisible.value = false;
      init();
    }
  }

  // 重置投票
  const resetVote = async () => {
    const res = await POST_RESET_VOTE();
    if (res.httpCode == 200) {
      ElMessage.success('操作成功！');
    }
  }
  
</script>

<style lang="scss" scoped>
.pagination{
  padding: 10px 10px 10px 0;
  display: flex;
  justify-content: end;
}
</style>